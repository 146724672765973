
<template>
  <div>
    <ul class="list-unstyled text-shadow HotspotList">
      <li v-for="(item,index) in hotspots" class="hotspotItem" style="pointer-events:auto;width: 52px;" :class="{'active':item.guid==editHotspotGuid}">
        <template v-if="item.type=='Image'">
          <a @click="setEditHotspot(item.guid)" style="cursor: pointer;">
            <div style="text-align:center;font-size:0;"><HotspotIconView :icon="item.icon" bgcolor="#ccc"></HotspotIconView></div>
            <div class="text-truncate" style="text-align:center;font-size:12px;">
              {{item.title||'未命名'}}
            </div>
          </a>
        </template>
        <template v-if="item.type=='Video'">
          <a @click="setEditHotspot(item.guid)">
            <div style="text-align:center;font-size:0;"><HotspotImageView :imageUrl="item.file.url.replace('/0/0/0/0/', '/200/0/0/0/')" bgcolor="#ccc"></HotspotImageView></div>
            <div class="text-truncate" style="text-align:center;font-size:12px;">
              {{item.title||`视频${index+1}`}}
            </div>
          </a>
        </template>
      </li>
      <li style="display:inline-block;margin:5px;pointer-events:auto;vertical-align: top;">
        <a @click="addNewHotspot()" style="cursor: pointer;">
          <div style="text-align:center">
            <div class="HotspotIconView" style="width:50px;height:50px;background-color:#ccc;    display: inline-block;">
              <i class="fas fa-plus fa-2x" style="line-height:50px;"></i>
            </div>
          </div>
          <div style="text-align:center;font-size:12px;">
            添加热点
          </div>
        </a>
      </li>
      <li style="display:inline-block;margin:5px;pointer-events:auto;vertical-align: top;">
        <a @click="addNewVideo()" style="cursor: pointer;">
          <div style="text-align:center">
            <div class="HotspotIconView" style="width:50px;height:50px;background-color:#ccc;    display: inline-block;">
              <i class="fas fa-plus fa-2x" style="line-height:50px;"></i>
            </div>
          </div>
          <div style="text-align:center;font-size:12px;">
            添加视频
          </div>
        </a>
      </li>
    </ul>
    <div class="PanoHotspotEdit">
      <template v-for="(item,index) in hotspots">
        <template v-if="item.guid==editHotspotGuid">
          <template v-if="item.type=='Image'">
            <div class="text-shadow" style="pointer-events:none;font-size:12px;">当前热点：{{item.title}}</div>
            <div v-if="showRange=='scale'" class="rangeBar">
              <div class="ppitem" title="缩放">
                <NumberButton v-model="item.scale" @change="setHotspot(item)" :defaultValue="0.5" :wheelRatio="0.0001" :moveRatio="0.01" step="0.01" :min="0.01" :max="2" name="缩放"></NumberButton>
              </div>
            </div>
            <div v-if="showRange=='rotate'" class="rangeBar">
              <div class="ppitem" title="旋转">
                <NumberButton v-model="item.rotate" @change="setHotspot(item)" :defaultValue="0" :wheelRatio="0.1" :moveRatio="1" step="1" :min="-180" :max="180" name="旋转"></NumberButton>
              </div>
            </div>
            <div class="text-right">
              <div class="btn-group btn-group-sm" role="group" style="width:100%">
                <button type="button" class="btn" :class="{'btn-primary':showRange=='scale','btn-default':showRange!='scale'}" @click="showRange=='scale'?showRange=null:showRange='scale'" @dblclick="item.scale=0.5;setHotspot(item)">缩放：{{item.scale}}</button>
                <button type="button" class="btn" :class="{'btn-primary':showRange=='rotate','btn-default':showRange!='rotate'}" @click="showRange=='rotate'?showRange=null:showRange='rotate'" @dblclick="item.rotate=0;setHotspot(item)">旋转：{{item.rotate}}°</button>
                <button type="button" class="btn btn-default" @click="showHotspotIconLibDialog(item.guid)">替换</button>
                <button type="button" class="btn btn-warning" @click="showHotspotLinkDialog(item.guid)">编辑</button>
              </div>
            </div>
            <div v-if="showRange=='rotation'&&item.distorted" class="rangeBar">
              <div class="mb-1" title="X轴">
                <NumberButton v-model="item.rx" @change="setHotspot(item)" :defaultValue="0" :wheelRatio="0.1" :moveRatio="1" step="1" :min="-180" :max="180" name="X轴"></NumberButton>
              </div>
              <div class="mb-1" title="Y轴">
                <NumberButton v-model="item.ry" @change="setHotspot(item)" :defaultValue="0" :wheelRatio="0.1" :moveRatio="1" step="1" :min="-180" :max="180" name="Y轴"></NumberButton>
              </div>
              <div class="mb-1" title="Z轴">
                <NumberButton v-model="item.rz" @change="setHotspot(item)" :defaultValue="0" :wheelRatio="0.1" :moveRatio="1" step="1" :min="-180" :max="180" name="Z轴"></NumberButton>
              </div>
            </div>
            <div class="text-right">
              <div class="btn-group btn-group-sm" role="group">
                <button type="button" class="btn btn-default" @click="item.distorted=!item.distorted;setHotspot(item)">扭曲：{{item.distorted?'是':'否'}}</button>
                <button v-if="item.distorted" type="button" class="btn" :class="{'btn-primary':showRange=='rotation','btn-default':showRange!='rotation'}" @click="showRange=='rotation'?showRange=null:showRange='rotation'">三维旋转</button>
                <button v-else type="button" class="btn btn-default" @click="item.zoom=!item.zoom;setHotspot(item)" :disabled="item.distorted">跟随缩放：{{item.zoom?'是':'否'}}</button>
                <button type="button" class="btn btn-default" @click="item.showTitle=!item.showTitle;setHotspot(item)">显示名称：{{item.showTitle?'是':'否'}}</button>
              </div>
            </div>

          </template>
          <template v-if="item.type=='Video'">
            <div class="text-shadow" style="pointer-events:none;font-size:12px;">当前</div>
            <div v-if="showRange=='scale'" class="rangeBar">
              <div class="ppitem" title="缩放">
                <NumberButton v-model="item.scale" @change="setHotspot(item)" :defaultValue="0.5" :wheelRatio="0.0001" :moveRatio="0.01" step="0.01" :min="0.01" :max="2" name="缩放"></NumberButton>
              </div>
            </div>
            <div v-if="showRange=='rotate'" class="rangeBar">
              <div class="ppitem" title="旋转">
                <NumberButton v-model="item.rotate" @change="setHotspot(item)" :defaultValue="0" :wheelRatio="0.1" :moveRatio="1" step="1" :min="-180" :max="180" name="旋转"></NumberButton>
              </div>
            </div>
            <div v-if="showRange=='chromakey'" class="rangeBar">
              <div class="mb-1">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <label for="" class="input-group-text">抠像颜色</label>
                  </div>
                  <input class="form-control" type="color" placeholder="抠像颜色" v-model="item.chromakeyColor" @change="setHotspot(item)" @input="setHotspot(item)" />
                  <div class="input-group-prepend">
                    <span class="input-group-text" @click="getColor(item)"><i class="fa fa-eye-dropper"></i></span>
                  </div>
                </div>
              </div>
              <div class="mb-1" title="范围">
                <NumberButton v-model="item.threshold" @change="setHotspot(item)" :defaultValue="0.01" :wheelRatio="0.0005" :moveRatio="0.001" step="0.001" :min="0" :max="1" name="范围"></NumberButton>
              </div>
              <div title="模糊">
                <NumberButton v-model="item.smoothing" @change="setHotspot(item)" :defaultValue="0.01" :wheelRatio="0.0005" :moveRatio="0.001" step="0.001" :min="0" :max="1" name="模糊"></NumberButton>
              </div>
            </div>
            <div class="text-right">
              <div class="btn-group btn-group-sm" role="group" style="width:100%">
                <button type="button" class="btn" :class="{'btn-primary':showRange=='scale','btn-default':showRange!='scale'}" @click="showRange=='scale'?showRange=null:showRange='scale'" @dblclick="item.scale=0.5;setHotspot(item)">缩放：{{item.scale}}</button>
                <button type="button" class="btn" :class="{'btn-primary':showRange=='rotate','btn-default':showRange!='rotate'}" @click="showRange=='rotate'?showRange=null:showRange='rotate'" @dblclick="item.rotate=0;setHotspot(item)">旋转：{{item.rotate}}°</button>
                <button type="button" class="btn" :class="{'btn-primary':showRange=='chromakey','btn-warning':showRange!='chromakey'}" @click="showRange=='chromakey'?showRange=null:showRange='chromakey'">抠像</button>
                <button type="button" class="btn btn-danger" @click="showDeleteHotspot()">删除</button>
              </div>
            </div>
            <div v-if="showRange=='rotation'&&item.distorted" class="rangeBar">
              <div class="mb-1" title="X轴">
                <NumberButton v-model="item.rx" @change="setHotspot(item)" :defaultValue="0" :wheelRatio="0.1" :moveRatio="1" step="1" :min="-180" :max="180" name="X轴"></NumberButton>
              </div>
              <div class="mb-1" title="Y轴">
                <NumberButton v-model="item.ry" @change="setHotspot(item)" :defaultValue="0" :wheelRatio="0.1" :moveRatio="1" step="1" :min="-180" :max="180" name="Y轴"></NumberButton>
              </div>
              <div class="mb-1" title="Z轴">
                <NumberButton v-model="item.rz" @change="setHotspot(item)" :defaultValue="0" :wheelRatio="0.1" :moveRatio="1" step="1" :min="-180" :max="180" name="Z轴"></NumberButton>
              </div>
            </div>
            <div class="text-right">
              <div class="btn-group btn-group-sm" role="group">
                <button type="button" class="btn btn-default" @click="item.distorted=!item.distorted;setHotspot(item)">扭曲：{{item.distorted?'是':'否'}}</button>
                <button v-if="item.distorted" type="button" class="btn" :class="{'btn-primary':showRange=='rotation','btn-default':showRange!='rotation'}" @click="showRange=='rotation'?showRange=null:showRange='rotation'">三维旋转</button>
                <button v-else type="button" class="btn btn-default" @click="item.zoom=!item.zoom;setHotspot(item)" :disabled="item.distorted">跟随缩放：{{item.zoom?'是':'否'}}</button>
                <button type="button" class="btn btn-default" @click="item.loop=!item.loop;setHotspot(item)">循环播放：{{item.loop?'是':'否'}}</button>
              </div>
            </div>
          </template>
        </template>
      </template>
    </div>
    <div v-if="getColorImg" style="position:fixed;left:0;right:0;top:0;bottom:0;z-index:1;">
      <ImageColorPicker :url="getColorImg" @change="colorPickerChange" />
    </div>
  </div>
</template>
<script>
  import HotspotIconView from '../../HotspotIconView'
  import HotspotImageView from '../../HotspotImageView'
  import NumberButton from '../../NumberButton'
  import ImageColorPicker from '../../ImageColorPicker'

  export default {
    components: {
      HotspotIconView,
      HotspotImageView,
      NumberButton,
      ImageColorPicker,
    },
    props: {
      config: {
        default() {
          return {}
        }
      },
    },
    data() {
      return {
        modelShow: null,
        modelHide: null,
        showRange: null,
        getColorImg: null,
        imgItem: null,
        types: [
          {
            key: '全景',
            value: 'Pano',
          },
          {
            key: '其他素材',
            value: 'File',
          },
          {
            key: '文字',
            value: 'Text',
          },
          {
            key: '链接',
            value: 'Link',
          },
        ],
      }
    },
    inject: {
      publicData: {
        default: {}
      },
      getComonent: {
        default: {}
      },
      getFunc: {}
    },
    computed: {
      editHotspotGuid() {
        return this.publicData.editHotspotGuid || null
      },
      linkdata() {
        for (var i in this.pano.hotspots) {
          if (this.pano.hotspots[i].guid == this.editHotspotGuid) {
            return this.pano.hotspots[i]
          }
        }
        return {}
      },
      hotspots() {
        if (!this.pano.hotspots) {
          return []
        }
        return this.pano.hotspots.filter((v) => v.type != 'Block')
      },
      editItemGuid() {
        return this.publicData.editItemGuid
      },
      editItemIndex() {
        for (var i in this.form.items) {
          if (this.form.items[i].guid == this.editItemGuid) {
            return i
          }
        }
        return null
      },
      editHotspot() {
        for (var i in this.hotspots) {
          if (this.hotspots[i].guid == this.editItemGuid) {
            return this.hotspots[i]
          }
        }
        return null
      },

      pano() {
        return this.publicData.pano || { hotspots: [] }
      },
      form() {
        return this.publicData.form || {}
      },
    },
    created() {
    },
    mounted() {
      this.$emit('ready', {
        vm: this,
        events: {
          setView: {
            target: 'Data',
            name: 'updatePano',
          },
          linkView: {
            name: 'renewSence',
            arg: 'linkView'
          },
          tweenView: {
            target: 'FullPano',
            name: 'tweenView'
          },
          addNewHotspot: {
            target: 'EditTourHotspotModels',
            name: 'addNewHotspot'
          },
          addNewVideo: {
            target: 'EditTourHotspotModels',
            name: 'addNewVideo'
          },
        },
        funcs: {
          setEditHotspot: this.setEditHotspotGuid,
          setEditVideo: this.setEditVideo,
          dragHotspot: this.dragHotspot,
        }
      })
      //if (this.editHotspotGuid) {
      //  var h = this.pano.hotspots[this.getHotspotIndex(this.editHotspotGuid)]
      //  this.getHotspot(h)
      //}
    },
    destroyed() {
    },
    methods: {
      dragHotspot(item, obj) {
        console.log('dragHotspot', item)
        switch (item.type) {
          case 'Video':
          case 'Image':
            this.getFunc({
              "target": "FullPano",
              "name": "dragHotspot"
            })(item)
            break
          default:
        }
      },
      krpano_makeScreenshot(width = 1000, height = 500) {
        return new Promise((resolve, reject) => {
          this.getFunc({ target: 'FullPano', name: 'makeScreenshot' })(resolve, width, height, 'imageUrl', true)
        })
      },
      updateHotspot(guid, settings, points) {
        this.getFunc({ target: 'FullPano', name: 'updateHotspot' })(guid, settings, points)
      },
      getKrpanoSize() {
        return this.getFunc({ target: 'FullPano', name: 'getKrpanoSize' })()
      },
      getColor(item) {
        this.updateHotspot(this.editHotspotGuid, { chromakey: 'null' })
        var { width, height } = this.getKrpanoSize()
        this.krpano_makeScreenshot(width, height).then((img) => {
          this.getColorImg = img
          this.imgItem = item
          //this.colorChange()
        })
      },
      colorPickerChange(color) {
        console.log(color, this.editHotspot)
        this.imgItem.chromakeyColor = color
        this.setHotspot(this.imgItem)
        this.getColorImg = null
        this.imgItem = null
      },
      setEditVideo(item) {
        this.getFunc('changePublicData')({
          editHotspotGuid: item.guid
        })
        this.getHotspot(item)
      },
      setEditHotspotGuid(item) {
        this.setEditHotspot(item.guid)
      },
      setEditHotspot(id) {
        if (this.editHotspotGuid == id) {
          this.showHotspotLinkDialog(id)
        } else {
          this.getFunc('changePublicData')({
            editHotspotGuid: id
          })
          var h = this.pano.hotspots[this.getHotspotIndex(id)]
          this.getHotspot(h)
        }
      },
      getHotspot(h) {
        var view = {
          hlookat: h.ath,
          vlookat: h.atv,
        }
        this.$emit('tweenView', view)
      },
      setHotspot(hotspot) {
        this.getFunc({ target: 'FullPano', name: 'setHotspot' })(hotspot)
      },
      showHotspotLinkDialog(guid) {
        this.getFunc('changePublicData')({
          editHotspotGuid: guid
        })
        this.getFunc({ target: 'EditTourHotspotModels', name: 'show' })()
      },
      showPanoLibDialog() {
        this.getFunc({ target: 'EditTourHotspotModels', name: 'panoLibshow' })()
      },
      showFileLibDialog() {
        this.getFunc({ target: 'EditTourHotspotModels', name: 'fileLibshow' })()
      },
      FileLibSelected(select) {
        if (select.guid == this.panoId) {
          this.$message({
            message: '您不能选择自身全景',
            type: 'error',
            zIndex: 9999,
            //showClose: true
          })
          return;
        }
        if (!this.linkdata) {
          this.$message({
            message: '程序错误',
            type: 'error',
            zIndex: 9999,
            //showClose: true
          })
          return;
        }
        this.linkdata.file = select;
        if (this.linkdata.title == '' || this.linkdata.title == null) {
          this.linkdata.title = select.name
          this.setHotspot(this.linkdata)
        }
      },
      showDeleteHotspot() {
        this.getFunc({ target: 'EditTourHotspotModels', name: 'deleteHotspotShow' })()
      },
      showHotspotIconLibDialog(guid) {
        this.getFunc('changePublicData')({
          editHotspotGuid: guid
        })
        this.getFunc({ target: 'EditTourHotspotModels', name: 'hotspotIconLibShow' })()
      },
      HotspotIconLibSelected(select) {
        var index = this.getHotspotIndex(this.editHotspotGuid)
        this.pano.hotspots[index].icon = select;
        this.setHotspot(this.pano.hotspots[index])
      },
      getHotspotIndex(guid) {
        for (var i in this.pano.hotspots) {
          if (this.pano.hotspots[i].guid == guid) {
            return i
          }
        }
        return null
      },
      addNewHotspot() {
        this.$emit('addNewHotspot')
      },
      addNewVideo() {
        this.$emit('addNewVideo')
      },
      numberMath(val, step, max, min) {
        val = Number(val)
        step = Number(step)
        val += step
        if (max && val > max) {
          val = max;
        }
        if (min && val < min) {
          val = min;
        }
        return Number(val);
      },
    },
  }
</script>
<style scoped>
  .HotspotList {
    position: absolute;
    top: 0;
    left: 0;
    right: 2.5rem;
    overflow: auto;
    white-space: nowrap;
    font-size: 14px;
    margin: 0;
    opacity: 0.75;
    /*pointer-events: none;*/
  }

  .hotspotItem {
    display: inline-block;
    margin: 5px;
    /*width: 4.5em;*/
    vertical-align: top;
  }

    .hotspotItem.active {
      margin: 4px;
      border: 1px solid #fff;
      box-shadow: #000 0 0 2px 0;
    }

    .hotspotItem .badge {
      position: absolute;
      right: 2px;
    }

  .PanoHotspotEdit {
    position: absolute;
    right: 0;
    bottom: 0;
    pointer-events: none !important;
    margin: 5px;
    opacity: 0.75;
  }

    .PanoHotspotEdit > * {
      pointer-events: auto;
      margin: 5px 0;
    }

  .rangeBar {
    background-color: #fff5;
    padding: 5px;
    margin: 0px 0 -5px;
    text-align: center;
    border-radius: 0.2rem;
  }
</style>
